import React, { useEffect, useState } from 'react';
import { client } from '../../lib/axiosClient';
import { useDisclosure } from '@mantine/hooks';
import { HeadPage } from '../../componants';
import { DeleteRequest, UpdateRequest } from '../../componants/Modal';
import { RequestsTabel } from '../../componants/Tables';
import Cookies from 'universal-cookie';
import { useRequests } from '../../api';
import { useTranslation } from 'react-i18next';
import { Container, Stack, Text } from '@mantine/core';
import { IconLock } from '@tabler/icons-react';
const cookies = new Cookies(null, { path: '/' });

export function Requests () {
    const { t } = useTranslation();
    const [requests, setRequests] = useState<any[]>([]);
    const [selectedData, setSelectedData] = useState(null);
    
    const [openedEditModal, { open: openEditModal, close: closeEditModal }] = useDisclosure(false);
    const [openedDeleteModal, { open: openDeleteModal, close: closeDeleteModal }] = useDisclosure(false);
    
    const {loading, error, data: dataRequests, refetch} = useRequests()
    const [role, setRole] = useState("");

    useEffect(() => {
        if (cookies.get('role')) {
            setRole(cookies.get('role'))
        }
    }, [cookies.get('role')])

    useEffect(() => {
        if (dataRequests.length >= 0) {
            setRequests(dataRequests)
        }
    }, [dataRequests])

    if (!["admin"].includes(role)) {
        return (
            <Container>
                <Stack align='center' justify='center' h={"calc(100vh - 130px)"}>
                    <IconLock size={45} strokeWidth={1.5} />
                    <Text>ليس لديك صلحيات للوصول لهذه الصفحة</Text>
                </Stack>
            </Container>
        )
    }

    return (
        <>
            <HeadPage
                page={"التسجيلات"}
                links={[
                    { title: t('couponsPage.links.link01'), href: '/dashboard' },
                    { title: "التسجيلات", href: '' }
                ]}
                labelCreate={""}
                labelExport={t('couponsPage.labelExport')}
                onCreate={() => console.log()}
                onExport={() => console.log()}
                hiddenExport={true}
            />

            <RequestsTabel
                data={requests}
                setSelectedData={setSelectedData}
                setOpenEditModal={openEditModal}
                setOpenDeleteModal={openDeleteModal}

                loading={loading}
            />

            <UpdateRequest title={"تعديل التسجيل"} data={selectedData} refetchData={refetch} opened={openedEditModal} onClose={closeEditModal} />
            <DeleteRequest data={selectedData} refetchData={refetch} opened={openedDeleteModal} onClose={closeDeleteModal} />
        </>
    );
}