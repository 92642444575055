import {Alert, Badge, Box, Button, Grid, Group, Stack, Text, useMantineTheme} from "@mantine/core";
import Modal, { Props as ModalProps } from "./Modal";
import { useEffect, useState } from "react";

import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import { useBalance, useInvoices, useStatisticsMarketer } from "../../api";
import { useMediaQuery } from "@mantine/hooks";
import { IconBrandWhatsapp, IconCurrencyDollar, IconPhoneCall, IconShoppingBag } from "@tabler/icons-react";
import { LineChart } from "@mantine/charts";
import Avatar from "react-avatar";
import { sortedData } from "../../lib/sort";

const cookies = new Cookies(null, { path: '/' });
const {Col} = Grid

type Props = {
    setSelectedData?: (id: string) => void;
    data: any;
    refetchData?: () => void;
} & ModalProps;

interface interfaceStatistics {
    "todayIncome": number,
    "beforeDayIncome": number,
    "weekIncome": number,
    "monthIncome": number,
    "todayOrders": number,
    "beforeDayOrders": number,
    "weekOrders": number,
    "monthOrders": number,
    "earnings": {
        "yearMonth": string,
        "confirmedEarnings": number,
        "pendingEarnings": number,
        "totalEarnings": number
    }[],
    "ordersYear": {
        "yearMonth": string,
        "ordersCount": number
    }[],
    "orders": {
        "total": number,
        "confirmed": number,
        "closed": number,
        "pending": number
    }
}

interface UserBalanceI {
    allConfirmedIncome: number,
    allPendingIncome: number,
    totalBills: number,
    requiredPayment: number
}

export const ShowStatisticsMarketer = ({data, ...props}: Props) => {
    const matches = useMediaQuery('(max-width: 64em)');
    const matchesSm = useMediaQuery('(max-width: 48em)');
    const theme = useMantineTheme();
    const { t } = useTranslation();
    const [statistics, setStatistics] = useState<interfaceStatistics>({
        "todayIncome": 0,
        "beforeDayIncome": 0,
        "weekIncome": 0,
        "monthIncome": 0,

        "todayOrders": 0,
        "beforeDayOrders": 0,
        "weekOrders": 0,
        "monthOrders": 0,
        "earnings": [],
        "ordersYear": [],
        "orders": {
            "total": 0,
            "confirmed": 0,
            "closed": 0,
            "pending": 0
        }
    });
    const [userBalance, setUserBalance] = useState<UserBalanceI>({
        allConfirmedIncome: 0,
        allPendingIncome: 0,
        totalBills: 0,
        requiredPayment: 0
    });


    const {loading, error, data: dataMarketer, fetchData: getStatisticsMarketer, refetch} = useStatisticsMarketer()
    const {loading: loadingBalance, error: errorBalance, data: dataBalance, refetch: refetchBalance} = useBalance({
        idMarketer: data?._id
    })
    
    useEffect(() => {
        if (props.opened && data !== null) {
            getStatisticsMarketer(data?.referralCode as string)
        }
    }, [data, props.opened])

    useEffect(() => {
        if (dataMarketer) {
            setStatistics(dataMarketer as any)
        }
    }, [dataMarketer])

    useEffect(() => {
        if (dataBalance) {
            setUserBalance(dataBalance as any)
        }
    }, [dataBalance])

    const closeModal = () => {
        props.onClose();
    };

    return (
        <Modal {...props} onClose={closeModal} loading={loading} size={matches ? "95%" : "90%"} footer={<></>} >
            <Grid>
                <Grid.Col span={{base: 12, md: 12}} mb={20}>
                    <Stack justify="center" align="center" gap={5}>
                        <Avatar name={data?.name} size="50" round={true} />
                        <Text size="md" fw={"bold"}>{data?.name}</Text>
                        <Badge size="xs">مسوق بالعمولة</Badge>
                        {data?.paymentMethod && data?.paymentAccount
                            ? <Text size="sm">{data?.paymentMethod}: {data?.paymentAccount}</Text>
                            : null
                        }
                        
                        
                        <Group justify="center" gap={5}>
                            <Button 
                                size="xs" color="blue" component="a"
                                leftSection={<IconPhoneCall size={14} />}
                                href={`tel://${data?.phone}`} 
                            >إتصال</Button>
                            <Button 
                                size="xs" color="green" component="a" target="_blank"
                                href={`https://wa.me/${data?.phone}`}
                                leftSection={<IconBrandWhatsapp size={14} />}
                            >واتساب</Button>
                        </Group>
                    </Stack>
                </Grid.Col>

                <Grid.Col span={{base: 12, md: 12}} mb={20}>
                    <Grid>
                        <Grid.Col span={{base: 12, sm: 6, md: 3}}>
                            <Box bg={"#fff"} p={20} style={{borderRadius: 8}}>
                                <Text size='xl' fw={'bold'} ta={'center'} mb={10}>{userBalance.allPendingIncome} د.ج</Text>
                                <Text size='md' ta={'center'}>رصيد غير مأكد</Text>
                            </Box>
                        </Grid.Col>
                        <Grid.Col span={{base: 12, sm: 6, md: 3}}>
                            <Box bg={"#fff"} p={20} style={{borderRadius: 8}}>
                                <Text size='xl' fw={'bold'} ta={'center'} mb={10}>{userBalance.allConfirmedIncome} د.ج</Text>
                                <Text size='md' ta={'center'}>رصيد مأكد</Text>
                            </Box>
                        </Grid.Col>
                        <Grid.Col span={{base: 12, sm: 6, md: 3}}>
                            <Box bg={"#fff"} p={20} style={{borderRadius: 8}}>
                                <Text size='xl' fw={'bold'} ta={'center'} mb={10}>{userBalance.requiredPayment} د.ج</Text>
                                <Text size='md' ta={'center'}>مطلوب للدفع</Text>
                            </Box>
                        </Grid.Col>
                        <Grid.Col span={{base: 12, sm: 6, md: 3}}>
                            <Box bg={"#fff"} p={20} style={{borderRadius: 8}}>
                                <Text size='xl' fw={'bold'} ta={'center'} mb={10}>{userBalance.totalBills} د.ج</Text>
                                <Text size='md' ta={'center'}>مدفوع</Text>
                            </Box>
                        </Grid.Col>
                    </Grid>
                </Grid.Col>

                <Grid.Col span={12} mb={10}>
                    <Text fz="xl" fw={600}>{t('homePage.sectionTitel01')}</Text>
                </Grid.Col>

                <Grid.Col span={{base: 12, md: 12}}>
                    <Grid>
                        <Grid.Col span={{base: 12, sm: 6, md: 3}}>
                            <Box bg={"#fff"} p={20} style={{borderRadius: 8}}>
                                <Text size='xl' fw={'bold'} ta={'center'} mb={10}>{statistics?.todayOrders || 0}</Text>
                                <Text size='md' ta={'center'}>طلبات اليوم</Text>
                            </Box>
                        </Grid.Col>
                        <Grid.Col span={{base: 12, sm: 6, md: 3}}>
                            <Box bg={"#fff"} p={20} style={{borderRadius: 8}}>
                                <Text size='xl' fw={'bold'} ta={'center'} mb={10}>{statistics?.beforeDayOrders || 0}</Text>
                                <Text size='md' ta={'center'}>طلبات امس</Text>
                            </Box>
                        </Grid.Col>
                        <Grid.Col span={{base: 12, sm: 6, md: 3}}>
                            <Box bg={"#fff"} p={20} style={{borderRadius: 8}}>
                                <Text size='xl' fw={'bold'} ta={'center'} mb={10}>{statistics?.weekOrders || 0}</Text>
                                <Text size='md' ta={'center'}>طلبات اخر 7 ايام</Text>
                            </Box>
                        </Grid.Col>
                        <Grid.Col span={{base: 12, sm: 6, md: 3}}>
                            <Box bg={"#fff"} p={20} style={{borderRadius: 8}}>
                                <Text size='xl' fw={'bold'} ta={'center'} mb={10}>{statistics?.monthOrders || 0}</Text>
                                <Text size='md' ta={'center'}>طلبات هذا الشهر</Text>
                            </Box>
                        </Grid.Col>
                    </Grid>
                </Grid.Col>
            </Grid>
        </Modal>
    );
};